import React, {useState} from 'react'
import { useMutation } from "react-query";
import apiClient from "../../http-common";
import {
  triggerSuccess,
  triggerError,
  setErrorMessage,
  setSuccessMessage,
} from '../Request/StatusFormatter';

const Mail = () => {    
const initialwallet = {
    title: '',
    content: '',
    receiver: '',
}
const [walletForm, setwalletForm] = useState(initialwallet);

const onChange = (e) =>
setwalletForm({ ...walletForm, [e.target.name]: e.target.value });

const { isLoading: isSendingRequest, mutate: postwallet } = useMutation(
    async () => {
      return await apiClient.post(`/api/send-mail`, {
        title: walletForm.title,
        content: walletForm.content,
        receiver: walletForm.receiver,
      });
    },
    {
      onSuccess: (res) => {
        setSuccessMessage(res.data);
        triggerSuccess(); 
      },
      onError: (err) => {
        setErrorMessage(err.response?.data || err);         
        triggerError();
      },
    }
  );


  return (
    <div id="page-content">
            <div className="card card-style">
                <div className="d-flex mx-3 mt-3 py-1">
                    <div className="align-self-center">
                        <h1 className="mb-0">Send Mail </h1>
                    </div>
                </div>
                <div className="divider divider-margins mt-3"></div>
                <form className="content mt-0">
                <div className="pb-2"></div>
                        <div className="form-custom form-label form-icon">
                            <i className="bi bi-code font-14"></i>
                            <input onChange={onChange} type="text" name="title" className="form-control rounded-xs bg-theme color-theme" id="title" placeholder="mail title"/>
                            <label htmlFor="title" className="color-highlight form-label-always-">Mail Title</label>
                            <span>(Mail Title)</span>
                        </div>
                        <div className="pb-2"></div>

                        <div className="form-custom form-label form-icon">
                            <i className="bi bi-code font-14"></i>
                            <textarea onChange={onChange} type="text" name="content" className="form-control rounded-xs bg-theme color-theme" id="content" placeholder="content"></textarea>
                            <label htmlFor="content" className="color-highlight form-label-always-">content</label>
                            <span>(content)</span>
                        </div>
                        <div className="pb-2"></div>

                        <div className="form-custom form-label form-icon">
                            <i className="bi bi-code font-14"></i>
                            <input onChange={onChange} type="email" name="receiver" className="form-control rounded-xs bg-theme color-theme" id="receiver" placeholder="receiver"/>
                            <label htmlFor="receiver" className="color-highlight form-label-always-">receiver email</label>
                            <span>(receiver email)</span>
                        </div>
                        <div className="pb-2"></div>

                        <div onClick={postwallet} className="btn btn-full gradient-green rounded-s shadow-bg shadow-bg-s mb-4">
                            {isSendingRequest? <span>sending...</span>
                            :
                            <span>Send Mail</span>
                            }                            
                        </div>                     
                </form>                
            </div>
        </div>
  )
}

export default Mail
import React, {useState, useEffect} from 'react';
import './App.css';
import { Routes, Route } from "react-router-dom";
import SideBar from './components/SideBar';
import Footer from './components/Footer';
import Header from './components/Header';
import SecondaryMenus from './components/SecondaryMenus';
import Index from './components/Home/Index';
import Login from './components/Auth/Login';
import Register from './components/Auth/Register';
import Forgot from './components/Auth/Forgot';
// import { Histories } from './components/Request/Histories';
import VerifyLogin from './components/Auth/VerifyLogin';
import Invest from './components/Investment/Invest';
import Payment from './components/Investment/Payment';
import Investment from './components/Investment/Investment';
import Withdrawal from './components/Withdrawal/Withdrawal';
import Referrals from './components/Referral/Referrals';
import Market from './components/Market';
import AdminInvestment from './components/Admin/AdminInvestments';
import Adminwithdrawal from './components/Admin/AdminWithdrawals';
import Adminreferral from './components/Admin/AdminReferrals';
import AdminUser from './components/Admin/AdminUser';
import ListUser from './components/Admin/ListUser';
import Profile from './components/Profile/Profile';
import Plans from './components/Admin/Plans';
import Wallets from './components/Admin/Wallets';
import LoginAlert from './components/LoginAlerts';
import Mail from './components/Admin/Mail';
import Invitation from './components/Home/Invitation';

function App() {
  // let navigate = useNavigate();
  const [isauthenticated, setAuth] = useState(false);
  const user = localStorage.getItem('delox-user');

  const confirmAuth = () =>{
    if (user === null) {
      setAuth(false);
    }else{
      setAuth(true);
    }
  }

 
useEffect(() => {
  confirmAuth();  
}, [])
    if (isauthenticated) {

      let currentAccount = localStorage.getItem('user-type');
      
      if (currentAccount === 'admin') {
        return (
          <div> 
              <Footer/>
                <div className="page-content footer-clear">
                    <Header/>    
                    <Routes>   
                    <Route path='/' element={<Index/>} />
                      <Route path='/invitation' element={<Invitation/>} />
                      <Route path='/dashboard' element={<Index/>}/>                                      
                      <Route path='/admin-investments' element={<AdminInvestment/>}/>                                                         
                      <Route path='/admin-withdrawals' element={<Adminwithdrawal/>}/>                                                         
                      <Route path='/admin-referrals' element={<Adminreferral/>}/>                                                         
                      <Route path='/list-users' element={<ListUser/>}/>                                                         
                      <Route path='/profile' element={<Profile/>}/>                                                         
                      <Route path='/plans' element={<Plans/>}/>                                                         
                      <Route path='/wallets' element={<Wallets/>}/>                                                         
                      <Route path='/admin-mail' element={<Mail/>}/>                                                         
                      <Route path='/view-account/:userId' element={<AdminUser/>}/> 
                      
                    </Routes>
                </div>
                <SideBar/>
                <SecondaryMenus/>  
                {/* <Histories/>  */}
                <script src="/scripts/bootstrap.min.js"></script>
                <script src="/scripts/custom.js"></script>                                     
          </div>
        );
      }else{
        return (
          <div> 
              <Footer/>
                <div className="page-content footer-clear">
                    <Header/>    
                    <Routes>   
                      <Route path='/invitation' element={<Invitation/>} />
                      <Route path='/' element={<Index/>} />
                      <Route path='/dashboard' element={<Index/>}/>                                     
                      <Route path='/invest' element={<Invest/>}/>                                     
                      <Route path='/payment' element={<Payment/>}/>                                     
                      <Route path='/investment' element={<Investment/>}/>  
                      <Route path='/withdrawal' element={<Withdrawal/>}/>                                                         
                      <Route path='/referral' element={<Referrals/>}/>                                                         
                      <Route path='/market' element={<Market/>}/>  
                      <Route path='/profile' element={<Profile/>}/>                                                        
                    </Routes>
                </div>
                <SideBar/>
                <SecondaryMenus/>  
                {/* <Histories/>  */}
                <script src="/scripts/bootstrap.min.js"></script>
                <script src="/scripts/custom.js"></script>                                     
          </div>
        );
      }
     
    }else{
      return (
        <div> 
          <div className="page-content footer-clear">
                <Routes>   
                <Route path='/invitation' element={<Invitation/>} />
                <Route path='/' element={<Login/>} />
                <Route path='/login' element={<Login/>} />
                <Route path='/register' element={<Register/>}/>
                <Route path='/forgot-password' element={<Forgot/>} />
                <Route path='/verify-user' element={<VerifyLogin/>} />
              </Routes>
          </div>        
          <LoginAlert/>             
          <script src="/scripts/bootstrap.min.js"></script>
          <script src="/scripts/custom.js"></script>                                     
        </div>
      );
    } 
}

export default App;

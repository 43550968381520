import React, {useEffect, useState} from 'react'
import { useMutation } from "react-query";
import apiClient from "../../http-common";
import {
  triggerSuccess,
  triggerError,
  setErrorMessage,
  setSuccessMessage,
} from '../Request/StatusFormatter';

const ManageWallet = () => {    
const initialwallet = {
    name: '',
    address: '',
}
const [walletForm, setwalletForm] = useState(initialwallet);
const [currentwallet, setCurrentwallet] = useState('');

const onChange = (e) =>
setwalletForm({ ...walletForm, [e.target.name]: e.target.value });

const { isLoading: isSendingRequest, mutate: postwallet } = useMutation(
    async () => {
      return await apiClient.post(`/api/update-wallet/${currentwallet}`, {
        name: walletForm.name,
        address: walletForm.address,
      });
    },
    {
      onSuccess: (res) => {
        setSuccessMessage(res.data);
        triggerSuccess(); 
        
      },
      onError: (err) => {
        setErrorMessage(err.response?.data || err);         
        triggerError();
      },
    }
  );

useEffect(() => {
    window.addEventListener('adminhistorywalletSelected', function (e) {
        let wallet = localStorage.getItem('adminhistorywalletSelected');
        if (wallet != null) {
            wallet = JSON.parse(wallet);

            setCurrentwallet(wallet.id);
            setwalletForm({...walletForm,
                name: wallet.wallet_name,
                address: wallet.wallet_address,
            })
        }
    }, false);        
  })

  const {name, address } = walletForm;
  return (
    <div id="admin-wallet-history-menu" className="offcanvas offcanvas-bottom offcanvas-attached">
            <div className="menu-size" style={{height: "640px"}}>
                <div className="d-flex mx-3 mt-3 py-1">
                    <div className="align-self-center">
                        <h1 className="mb-0">{name}</h1>
                    </div>
                    <div className="align-self-center ms-auto">
                        <a href="/#" className= "ps-4 shadow-0 me-n2" data-bs-dismiss="offcanvas">
                            <i className="bi bi-x color-red-dark font-26 line-height-xl"></i>
                        </a>
                    </div>
                </div>
                <div className="divider divider-margins mt-3"></div>
                <form key={currentwallet} className="content mt-0">
                <div className="pb-2"></div>
                        <div className="form-custom form-label form-icon">
                            <i className="bi bi-code font-14"></i>
                            <input onChange={onChange} defaultValue={name} type="text" name="name" className="form-control rounded-xs bg-theme color-theme" id="name" placeholder="wallet name"/>
                            <label htmlFor="name" className="color-highlight form-label-always-">Wallet Name</label>
                            <span>(Wallet Name)</span>
                        </div>
                        <div className="pb-2"></div>

                        <div className="form-custom form-label form-icon">
                            <i className="bi bi-code font-14"></i>
                            <textarea onChange={onChange} defaultValue={address} type="text" name="address" className="form-control rounded-xs bg-theme color-theme" id="address" placeholder="address"></textarea>
                            <label htmlFor="address" className="color-highlight form-label-always-">address</label>
                            <span>(address)</span>
                        </div>
                        <div className="pb-2"></div>

                        <div onClick={postwallet} className="btn btn-full gradient-green rounded-s shadow-bg shadow-bg-s mb-4">
                            {isSendingRequest? <span>updating...</span>
                            :
                            <span>Update wallet</span>
                            }                            
                        </div>                     
                </form>                
            </div>
        </div>
  )
}

export default ManageWallet

export const triggerSuccess = () => {
  var successMenu = document.querySelector('#success-modal');
  if(successMenu){
        setTimeout(function(){
            var activateSuccess = new window.bootstrap.Offcanvas(successMenu)
            activateSuccess.show();               
        },600)
    }
       
};

export const triggerError = () => {
  var errorMenu = document.querySelector('#error-modal');
        if(errorMenu){
            setTimeout(function(){
                var activateError = new window.bootstrap.Offcanvas(errorMenu)
                activateError.show();
            },600)
        }
};

export const triggerPending = () => {
  var statusMenu = document.querySelector('#pending-modal');
        if(statusMenu){
            setTimeout(function(){
                var activateStatus = new window.bootstrap.Offcanvas(statusMenu)
                activateStatus.show();
            },600)
        }
};

export const setErrorMessage = (message) => {
  localStorage.setItem('error', JSON.stringify(message));
  const errorEvent = new Event('updatedErrorStorage');
  window.dispatchEvent(errorEvent);

}

export const setSuccessMessage = (message) => {
  localStorage.setItem('success', JSON.stringify(message));
  const successEvent = new Event('updatedSuccessStorage');
  window.dispatchEvent(successEvent);

}




import React, {useEffect, Fragment} from 'react';
import {notifications} from "../Request/Notifications";
import { Link } from 'react-router-dom';

const Notifications = () => {
    const myNotifications = notifications();
    const getDate = (date) => new Date(date).toDateString();

  return (
    <div>
        <div id="menu-notifications" className="offcanvas offcanvas-top offcanvas-detached rounded-m">
            <div className="menu-size" style={{height: "365px"}}>
                <div className="d-flex mx-3 mt-3 py-1">
                    <div className="align-self-center">
                        <h1 className="mb-0">Notifications</h1>
                    </div>
                    <div className="align-self-center ms-auto">
                        <a href="/" className="py-3 ps-4 shadow-0 me-n2" data-bs-dismiss="offcanvas">
                            <i className="bi bi-x color-red-dark font-26"></i>
                        </a>
                    </div>
                </div>
                <div className="content pb-2">
                    {myNotifications.length > 0 &&
                    <div className="card card-style">
                        <div className="content">
                            {myNotifications.map(notification =>(
                                <Fragment key={notification.id}>
                                    <Link to="/" className="d-flex py-1">
                                        <div className="align-self-center">
                                            <span className="icon rounded-m me-2 gradient-green shadow-bg shadow-bg-xs"><i className="material-icons-outlined color-white" style={{fontSize: "24px", marginTop: "11px"}}>{notification.data.icon}</i></span>
                                        </div>    
                                        <div className="align-self-center ps-1">
                                            <h5 className="pt-1 mb-n1">{notification.data.title}</h5>
                                            <p className="mb-0 font-11 opacity-50">{getDate(notification.created_at)}</p>
                                        </div>
                                        <div className="align-self-center ms-auto text-end">
                                            <h4 className={`pt-1 mb-n1 ${notification.data.status_color}`}>{notification.data.bold_value}</h4>
                                            <p className="mb-0 font-11"> {notification.data.status_text}</p>
                                        </div>
                                    </Link>
                                    <div className="divider my-2 opacity-50"></div>
                                </Fragment>
                            ))}
                        </div>
                    </div>
                    }
                </div>
            </div>
        </div>
    </div>
  )
}

export default Notifications
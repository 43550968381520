import React, {useEffect, useState} from 'react';
import apiClient from "../../http-common";
import { useQuery } from "react-query";

export const Notifications = () => {
    const refresh = () => window.location.reload();
    
    const { isLoading: isLoadingNotifications, refetch: getNotifications } = useQuery(
        "account-notifications",
        async () => {
          return await apiClient.get("/api/notifications");
        },
        {
          enabled: true,
          onSuccess: (res) => {
            if (res.data.status === 'success') {
    
                let accountNotifications = {
                    'notifications': res.data.notifications,
                    'unreadNotifications' : res.data.unread_notifications,
                }
                localStorage.setItem('deloxbank-account-notifications', JSON.stringify(accountNotifications));
                const event = new Event('updatedStorage');
                window.dispatchEvent(event);
                
            }
          },
          onError: (err) => {
            console.log(err.response?.data || err);
          },
        }
      );
    
      useEffect(() => {
        getNotifications();
        window.addEventListener('storage', () => {
            notifications();
            unreadNotifications();
        });
      }, [isLoadingNotifications]);
      
      return (
            <div className="icon bg-theme color-theme rounded-circle ">
              {isLoadingNotifications ?
              <i className="material-icons-sharp"  style={{fontSize: "36px", marginTop: "8px"}}>sync</i>
              :
              <i onClick={refresh} className="material-icons-sharp"  style={{fontSize: "36px", marginTop: "8px"}}>refresh</i>}
            </div>
          )
}

export const notifications = () => {
    let myNotifications = [];
    let storedNotifications = localStorage.getItem('deloxbank-account-notifications');
    if (storedNotifications !== null) {
        let data = JSON.parse(storedNotifications);
        myNotifications = data.notifications;
    }
    return myNotifications;
}

export const unreadNotifications = () => {
    let unreadNotifications = [];
    let storedNotifications = localStorage.getItem('deloxbank-account-notifications');
    if (storedNotifications !== null) {
        let data = JSON.parse(storedNotifications);
        unreadNotifications = data.unreadNotifications;
    }
    return unreadNotifications;
}

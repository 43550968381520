import React, {useEffect, useState} from 'react';
import { Link } from "react-router-dom";
import {useMediaQuery} from '@react-hook/media-query'
import Analysis from './Analysis';
import {bonusBalance } from '../Request/Wallets';
import {newBalance, newActiveDeposit, newProfit, newRefBalance} from '../Request/Wallets';

const Index = () => {

    const [upnewBalance, setnewBalance] = useState(newBalance());
    const [upnewActiveDeposit, setnewActiveDeposit] = useState(newActiveDeposit());
    const [upnewProfit, setnewProfit] = useState(newProfit());
    const [upnewRefBalance, setnewRefBalance] = useState(newRefBalance());
    const [upbonusBalance, setbonusBalance] = useState(bonusBalance());

    const lastnewBalance = () => {
        let balance = 0;
        let storedBalance = localStorage.getItem('binaryfair-wallet-balances');
        if (storedBalance !== null) {
            let data = JSON.parse(storedBalance);
            balance = data.new_balance;
        }
        return balance;
        }
    
    const lastnewActiveDeposit = () => {
        let balance = 0;
        let storedBalance = localStorage.getItem('binaryfair-wallet-balances');
        if (storedBalance !== null) {
            let data = JSON.parse(storedBalance);
            balance = data.new_active_deposit;
        }
        return balance;
        }

        const lastnewProfit = () => {
        let balance = 0;
        let storedBalance = localStorage.getItem('binaryfair-wallet-balances');
        if (storedBalance !== null) {
            let data = JSON.parse(storedBalance);
            balance = data.new_profit;
        }
        return balance;
        }

        const lastnewRefBalance = () => {
            let balance = 0;
            let storedBalance = localStorage.getItem('binaryfair-wallet-balances');
            if (storedBalance !== null) {
                let data = JSON.parse(storedBalance);
                balance = data.new_ref_balance;
            }
            return balance;
            }

            const lastbonusBalance = () => {
                let balance = 0;
                let storedBalance = localStorage.getItem('binaryfair-wallet-balances');
                if (storedBalance !== null) {
                    let data = JSON.parse(storedBalance);
                    balance = data.bonus_balanace;
                }
                return balance;
              }

    
    let accountType = localStorage.getItem('user-type');
    let user = localStorage.getItem('delox-user');
    if (user != null) {
        user = JSON.parse(user);
    }
    const matches = useMediaQuery('only screen and (max-width: 480px)')
    useEffect(() => {
        if(window && window.init_template){
            window.init_template();
        }
        
        window.addEventListener('updatedStorage', function (e) {
            
            setnewBalance(lastnewBalance());
            setnewActiveDeposit(lastnewActiveDeposit());
            setnewProfit(lastnewProfit());
            setnewRefBalance(lastnewRefBalance());
            setbonusBalance(lastbonusBalance());

        }, false);  
    });

const getAmount = (amount)=> new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD', currencySign: 'accounting' }).format(amount);
   return (
<div id="page-content">
    {/* <!-- Main Card Slider--> */}
    <div className={`splide ${matches ? 'single-slider': 'triple-slider'} slider-no-dots  slider-no-arrows slider-visible mt-n8`} id="single-slider-1">
        <div className="splide__track">
            <div className="splide__list">
                <div className="splide__slide">
                    <div className="card m-0 card-style bg-5 shadow-card shadow-card-m shadow-sm" style={{height: "170px", backgroundImage: "url(https://res.cloudinary.com/dzxyvdq14/image/upload/v1653128509/cardrrw3.jpg)"}}>
                        <div className="card-top p-3">
                            <Link to="#" data-bs-toggle="offcanvas" data-bs-target="#menu-withdrawal" className="icon icon-xxs bg-theme color-theme float-end"><i className="bi bi-three-dots font-18"></i></Link>
                        </div>
                        <div className="card-center">
                            <div className="bg-theme px-3 py-2 rounded-end d-inline-block">
                                <h1 className="font-13 my-n1">
                                    <a className="color-theme" data-bs-toggle="collapse" href="#fundingWallet" aria-controls="fundingWallet">Click for Balance</a>
                                </h1>
                                <div className="collapse" id="fundingWallet"><h2 className="color-theme font-26">{getAmount(upnewBalance)}</h2></div>
                            </div>
                        </div>
                        <strong className="card-top no-click font-18 p-3 color-white font-monospace font-weight-bold">Account Balance</strong>
                        <strong className="card-bottom no-click p-3 text-start color-white font-monospace">updated now</strong>
                        <strong className="card-bottom no-click p-3 text-end color-white font-monospace">{new Date().toLocaleTimeString()}</strong>
                        <div className="card-overlay "></div>
                    </div>
                </div>

                <div className="splide__slide">
                    <div className="card m-0 card-style bg-7 shadow-card shadow-card-m shadow-sm" style={{height: "170px", backgroundImage: "url(https://res.cloudinary.com/dzxyvdq14/image/upload/v1653128509/cardrrw3.jpg)"}}>
                        <div className="card-top p-3">
                            <Link to="#" data-bs-toggle="offcanvas" data-bs-target="#menu-withdrawal" className="icon icon-xxs bg-theme color-theme float-end"><i className="bi bi-three-dots font-18"></i></Link>
                        </div>
                        <div className="card-center">
                            <div className="bg-theme px-3 py-2 rounded-end d-inline-block">
                                <h1 className="font-13 my-n1">
                                    <a className="color-theme" data-bs-toggle="collapse" href="#referralBalance" aria-controls="referralBalance">Click for Balance</a>
                                </h1>
                                <div className="collapse" id="referralBalance"><h2 className="color-theme font-26">{getAmount(upnewRefBalance)}</h2></div>
                            </div>
                        </div>
                        <strong className="card-top no-click font-18 p-3 color-white font-monospace font-weight-bold">Referral Wallet</strong>
                        <strong className="card-bottom no-click p-3 text-start color-white font-monospace">updated now</strong>
                        <strong className="card-bottom no-click p-3 text-end color-white font-monospace">{new Date().toLocaleTimeString()}</strong>
                        <div className="card-overlay "></div>
                    </div>
                </div>


                <div className="splide__slide">
                    <div className="card m-0 card-style bg-7 shadow-card shadow-card-m shadow-sm" style={{height: "170px", backgroundImage: "url(https://res.cloudinary.com/dzxyvdq14/image/upload/v1653128509/cardrrw3.jpg)"}}>
                        <div className="card-top p-3">
                            <Link to="#" data-bs-toggle="offcanvas" data-bs-target="#menu-withdrawal" className="icon icon-xxs bg-theme color-theme float-end"><i className="bi bi-three-dots font-18"></i></Link>
                        </div>
                        <div className="card-center">
                            <div className="bg-theme px-3 py-2 rounded-end d-inline-block">
                                <h1 className="font-13 my-n1">
                                    <a className="color-theme" data-bs-toggle="collapse" href="#bonusBalance" aria-controls="bonusBalance">Click for Balance</a>
                                </h1>
                                <div className="collapse" id="bonusBalance"><h2 className="color-theme font-26">{getAmount(upbonusBalance)}</h2></div>
                            </div>
                        </div>
                        <strong className="card-top no-click font-18 p-3 color-white font-monospace font-weight-bold">Bonus Wallet</strong>
                        <strong className="card-bottom no-click p-3 text-start color-white font-monospace">updated now</strong>
                        <strong className="card-bottom no-click p-3 text-end color-white font-monospace">{new Date().toLocaleTimeString()}</strong>
                        <div className="card-overlay "></div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    
    {/* <!-- Quick Actions --> */}
    {accountType !== 'admin' && 
    <div className="content py-2">
        <div className="d-flex justify-content-end text-center">
            <div className="me-auto">
                <Link to="/invest" className="icon icon-xxl rounded-circle bg-theme shadow-m color-theme"><i className="material-icons-outlined" style={{fontSize: "30px", marginTop: "18px"}}>analytics</i></Link>
                <h6 className="font-13 opacity-80 font-500 mb-0 pt-2">Invest</h6>
            </div>
            <div className="m-auto">
                <Link to="/withdraw" data-bs-toggle="offcanvas" data-bs-target="#menu-withdrawal" className="icon icon-xxl rounded-circle bg-theme shadow-m color-theme"><i className="material-icons-outlined" style={{fontSize: "30px", marginTop: "18px"}}>money_off_csred</i></Link>
                <h6 className="font-13 opacity-80 font-500 mb-0 pt-2">Withdraw</h6>
            </div>
            <div className="m-auto">
                <Link to="/referral" className="icon icon-xxl rounded-circle bg-theme shadow-m color-theme"><i className="material-icons-outlined" style={{fontSize: "30px", marginTop: "18px"}}>diversity_2</i></Link>
                <h6 className="font-13 opacity-80 font-500 mb-0 pt-2">Referral</h6>
            </div>
            <div className="ms-auto">
                <Link to="/market" className="icon icon-xxl rounded-circle bg-theme shadow-m color-theme"><i className="material-icons-outlined" style={{fontSize: "30px", marginTop: "18px"}}>candlestick_chart</i></Link>
                <h6 className="font-13 opacity-80 font-500 mb-0 pt-2">market</h6>
            </div>
        </div>
    </div> 
    }
        
        <div className="row text-center">        
            <div className="col-md-3 mb-n2 text-start">
                <div className="default-link card card-style" style={{height: "90px"}}>
                    <div className="card-center px-4">
                        <div className="d-flex">
                            <div className="align-self-center">
                                <span className="icon icon-m rounded-s gradient-green shadow-bg shadow-bg-xs">
                                <i className="material-icons-outlined color-white" style={{fontSize: "28px", marginTop: "11px"}}>account_balance</i>
                                </span>
                            </div>
                            <div className="align-self-center ms-auto text-end">
                                <h1 className="font-20 mb-n1">{getAmount(upnewBalance)}</h1>
                                <p className="mb-0 font-12 opacity-70">Account Balance</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>	

            <div className="col-md-3 mb-n2 text-start">
                <div className="default-link card card-style" style={{height: "90px"}}>
                    <div className="card-center px-4">
                        <div className="d-flex">
                            <div className="align-self-center">
                                <span className="icon icon-m rounded-s gradient-green shadow-bg shadow-bg-xs">
                                <i className="material-icons-outlined color-white" style={{fontSize: "28px", marginTop: "11px"}}>paid</i>
                                </span>
                            </div>
                            <div className="align-self-center ms-auto text-end">
                                <h1 className="font-20 mb-n1">{getAmount(upnewActiveDeposit)}</h1>
                                <p className="mb-0 font-12 opacity-70">Active Deposit</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="col-md-3 mb-n2 text-start">
                <div className="default-link card card-style" style={{height: "90px"}}>
                    <div className="card-center px-4">
                        <div className="d-flex">
                            <div className="align-self-center">
                                <span className="icon icon-m rounded-s gradient-green shadow-bg shadow-bg-xs">
                                <i className="material-icons-outlined color-white" style={{fontSize: "28px", marginTop: "11px"}}>attach_money</i>
                                </span>
                            </div>
                            <div className="align-self-center ms-auto text-end">
                                <h1 className="font-20 mb-n1">{getAmount(upnewProfit)}</h1>
                                <p className="mb-0 font-12 opacity-70">Profit</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="col-md-3 mb-n2 text-start">
                <div className="default-link card card-style" style={{height: "90px"}}>
                    <div className="card-center px-4">
                        <div className="d-flex">
                            <div className="align-self-center">
                                <span className="icon icon-m rounded-s gradient-green shadow-bg shadow-bg-xs">
                                    <i className="material-icons-outlined color-white" style={{fontSize: "28px", marginTop: "11px"}}>card_giftcard</i>
                                </span>
                            </div>
                            <div className="align-self-center ms-auto text-end">
                                <h1 className="font-20 mb-n1">{getAmount(upnewRefBalance)}</h1>
                                <p className="mb-0 font-12 opacity-70">Referral Bonus</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>	
        </div>
    <Analysis/>   
</div>
  )
}

export default Index
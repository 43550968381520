import React from 'react';
import { Link } from "react-router-dom";
import { useMutation } from "react-query";
import apiClient from "../http-common";
// import {notifications, Notifications} from "./Request/Notifications";
import {Histories} from './Request/Histories';

const Header = () => {
    let user = localStorage.getItem('delox-user');
    if (user != null) {
        user = JSON.parse(user);
    }

    const { isLoading: isSendingRequest, mutate: postlogout } = useMutation(
        async () => {
          return await apiClient.post(`/api/logout`);
        },
        {
          onSuccess: (res) => {
              if (res.data.message === 'successful') {
                    localStorage.removeItem('delox-user');
                    localStorage.removeItem('user-type');
                    localStorage.removeItem('binaryfair-account-histories');
                    localStorage.removeItem('binaryfair-wallet-balances'); 
                window.location.href = '/';
              }            
          },

          onError: (err) => {
            console.log(err.response?.data || err);
          },
        }
      );
    
    
  return (
    <div className="pt-1 mb-4 fixed-top shadow-lg bg-theme">
        <div className="page-title d-flex">
            <div className="align-self-center me-auto">
                <Histories/>
            </div>
            <div className="align-self-center ms-auto">
                <Link to="/#"
                style={{marginRight: "20px"}}
                data-bs-toggle="offcanvas"
                data-bs-target="#menu-notifications"
                className="icon bg-theme color-theme">
                    <div style={{marginRight: "20px"}}>{user.name}</div>
                </Link>
                
                <Link to="#"
                data-bs-toggle="dropdown"
                className="icon bg-theme shadow-bg shadow-bg-s rounded-circle shadow-sm">
                    <img src={user.profile} width="45" className="rounded-m" alt="img"/>
                </Link>
                <div className="dropdown-menu">
                    <div className="card card-style shadow-m mt-1 me-1">
                        <div className="list-group list-custom list-group-s list-group-flush rounded-xs px-3 py-1">
                            
                            <Link to="/profile" className="list-group-item">
                                <i className="has-bg gradient-yellow shadow-bg shadow-bg-xs color-white rounded-xs bi bi-person-circle"></i>
                                <strong className="font-13">Profile</strong>
                            </Link>

                            <span onClick={postlogout} className="list-group-item">
                                <i className="has-bg gradient-red shadow-bg shadow-bg-xs color-white rounded-xs bi bi-power"></i>
                                <strong className="font-13">Log Out {isSendingRequest && <span>Logging out...</span>}</strong>
                            </span>
                        </div>
                    </div>
                </div>
            </div>
            <div className="align-self-center ms-auto">
                <Link to="#"
                data-bs-toggle="offcanvas" id="footer-toggle" data-bs-target="#menu-sidebar">
                    <i translate="no" className="material-icons-sharp color-theme" style={{fontSize: "28px", marginTop: "12px", marginLeft: "15px"}}>menu</i>
                </Link>
            </div>
        </div>
    </div>
  )
}
export default Header
import React, {useState, useEffect} from 'react';
import { Link } from 'react-router-dom';
import { useMutation } from "react-query";
import apiClient from "../../http-common";
import Clock from '../Investment/Clock';
import { accountBalance, referralBalance } from '../Request/Wallets';

import {
    triggerSuccess,
    triggerError,
    setErrorMessage,
    setSuccessMessage,
  } from '../Request/StatusFormatter';
  
const Profile = () => {
  const getAmount = (amount)=> new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD', currencySign: 'accounting' }).format(amount);

    let user = localStorage.getItem('delox-user');
    if (user != null) {
        user = JSON.parse(user);
    }
    
    const initialImage = {
        image: null
    }
    const [formImage, setImage] = useState(initialImage);
    const  onFileChange = (event) => { 
        setImage({...formImage, image: event.target.files[0]});
      }; 

      const { isLoading: isSendingRequest, mutate: postFunding } = useMutation(
        async () => {
            
		const formData = new FormData(); 
		formData.append('image', formImage.image);
        return await apiClient.post('/api/profile-update', formData);
        },
        {
          onSuccess: (res) => {
            setSuccessMessage(res.data);
            triggerSuccess();  
            localStorage.setItem('delox-user', JSON.stringify(res.data.user));   
            const uploadEvent = new Event('profileUploaded');
            window.dispatchEvent(uploadEvent);       
          },
          onError: (err) => {
            setErrorMessage(err.response?.data || err);         
            triggerError();
          },
        }
      );
      useEffect(()=>{
        window.addEventListener('profileUploaded', function (e) {
            let localUser = localStorage.getItem('delox-user');
            if (localUser != null) {
                localUser = JSON.parse(localUser);
                user = localUser;
            }

              
        }, false);
    })
  return (
    <div id="page-content">
        <div className="notch-clear"></div>
       <div className="pt-5 mt-4"></div>
       <div className="card card-style overflow-visible mt-5">
           <div className="mt-n5"></div>
           {formImage.image === null ? 
           <img style={{width: "180px", height: "180px"}} src={user.profile} alt="profile" className="mx-auto rounded-circle mt-n5 shadow-l"/>
           :
           <img style={{width: "180px", height: "180px"}} src={URL.createObjectURL(formImage.image)} alt="profile" className=" mx-auto rounded-circle mt-n5 shadow-l"/>
           }
           <input onChange={onFileChange} type="file" id='profile' name="profile" accept="image/*" style={{display: "none"}}/>
            <label className='text-center' htmlFor='profile' style={{transform: "translate(20px, -20px)", zIndex: '20'}}>
           <span className="material-icons-outlined color-theme" style={{fontSize: "38px"}} >add_a_photo</span>
           </label>
            {formImage.image !== null &&
                <div className='text-center p-8 mb-4 mt-n2'>
                    <button onClick={postFunding} className='btn btn-sm gradient-green'>
                        {isSendingRequest ? <span>uploading...</span>: <span>update profile</span>}
                    </button>
                </div>
            }
           <h1 className="color-theme text-center font-30 pt-0 mb-0">{user.name}</h1>
           <div className="content mt-0 mb-2">
                <div className="list-group list-custom list-group-flush list-group-m rounded-xs">
                  <Clock/>
                </div>
           </div>
       </div>


       <div className="card-stack" data-stack-height="180">
            {/* <!-- Card Open on Click--> */}
            <div className="card-stack-click"></div>
            {/* <!-- Card 1--> */}
            <div className="card card-style bg-5" style={{backgroundImage: "url(https://res.cloudinary.com/dzxyvdq14/image/upload/v1653127005/cardrrw.jpg)"}}>
                <div className="card-top p-3">
                    <a href="/#" data-bs-toggle="offcanvas" data-bs-target="#menu-card-more" className="icon icon-xxs bg-theme color-theme float-end"><i className="bi bi-three-dots font-18"></i></a>
                </div>
                <div className="card-center">
                    <div className="bg-theme px-3 py-2 rounded-end d-inline-block">
                        <h1 className="font-13 my-n1">
                            <a className="color-theme" data-bs-toggle="collapse" href="#fundingWallet" aria-controls="fundingWallet">Click for Balance</a>
                        </h1>
                        <div className="collapse" id="fundingWallet"><h2 className="color-theme font-26">{getAmount(accountBalance())}</h2></div>
                    </div>
                </div>
                <strong className="card-top no-click font-12 p-3 color-white font-monospace">Mining Earning</strong>
                <strong className="card-bottom no-click p-3 text-start color-white font-monospace">updated now</strong>
                <strong className="card-bottom no-click p-3 text-end color-white font-monospace">{new Date().toLocaleTimeString()}</strong>
                <div className="card-overlay "></div>
            </div>


            {/* card 4 */}
            <div className="card card-style bg-9" style={{backgroundImage: "url(https://res.cloudinary.com/dzxyvdq14/image/upload/v1653127005/cardrrw.jpg)"}}>
                <div className="card-top p-3">
                    <a href="/#" data-bs-toggle="offcanvas" data-bs-target="#menu-card-more" className="icon icon-xxs bg-theme color-theme float-end"><i className="bi bi-three-dots font-18"></i></a>
                </div>
                <div className="card-center">
                    <div className="bg-theme px-3 py-2 rounded-end d-inline-block">
                        <h1 className="font-13 my-n1">
                            <a className="color-theme" data-bs-toggle="collapse" href="#InterestBalance" aria-controls="InterestBalance">Click for Balance</a>
                        </h1>
                        <div className="collapse" id="InterestBalance"><h2 className="color-theme font-26">{getAmount(referralBalance())}</h2></div>
                    </div>
                </div>
                <strong className="card-top no-click font-12 p-3 color-white font-monospace">Referral Earning</strong>
                <strong className="card-bottom no-click p-3 text-start color-white font-monospace">updated now</strong>
                <strong className="card-bottom no-click p-3 text-end color-white font-monospace">{new Date().toLocaleTimeString()}</strong>
                <div className="card-overlay "></div>
            </div>
        </div>

        {/* <!-- Card Stack Info Message / Hides when deployed --> */}
        <h6 className="btn-stack-info color-theme opacity-80 text-center mt-n2 mb-3">Tap the Cards to Expand your Wallet</h6>
        {/* <!-- Card Stack Button / shows when deployed --> */}
        <a href="/#" onClick={ (event) => event.preventDefault() } className="disabled btn-stack-click btn mx-3 mb-4 btn-full gradient-green shadow-bg shadow-bg-xs">Close my Wallet</a>
        {/* <!-- Tabs--> */}


    </div>
  )
}

export default Profile
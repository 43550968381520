import React, {useEffect, useState} from 'react';
import {usersHistory} from '../Request/Admin';
import { Link } from 'react-router-dom';

const ListUser = () => {
    let userList = usersHistory();
    useEffect(() => {
        userList = usersHistory();
        if(window && window.init_template){
            window.init_template();
        }  
    });

  return (
    <div id="page-content">
        <h2 className='text-center mb-2'>
            List of users
        </h2>
        <div className="card card-style px-0">
        <div className="content mt-0 mb-0">
            {userList.data.map(user => (
                <Link key={user.id} to={`/view-account/${user.id}`} className="d-flex pb-3 border-bottom">
                    <div className="align-self-center">
                        <span className="icon icon-xxl gradient-yellow me-2 shadow-bg shadow-bg-s rounded-s">
                            <img src={user.profile} width="45" className="rounded-s" alt="img"/>
                        </span>
                    </div>
                    <div className="align-self-center ps-1">
                        <h4 className="pt-1 mb-n1 color-theme">{user.email}</h4>
                        <p className="mb-0 font-12">{user.name}</p>
                    </div>
                </Link> 
            ))}
        </div>
    </div>
    </div>
  )
}

export default ListUser